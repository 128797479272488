import bitebybiteConfig from './bitebybite';
import cozyCoffeeConfig from './cozycoffee';
import defaultConfig from './default';
import nocConfig from './noc';
import tomacadoConfig from './tomacado';
import tunglanConfig from './tunglan';

const merchants = {
  biteByBite: bitebybiteConfig,
  cozyCoffee: cozyCoffeeConfig,
  noc: nocConfig,
  tomacado: tomacadoConfig,
  tungLan: tunglanConfig,
};

export default merchants[process.env.REACT_APP_MERCHANT] || defaultConfig;
