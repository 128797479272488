const tomacadoConfig = {
  firebaseConfig: {
    apiKey: "AIzaSyA0T4uAj_9PGNt0TE9QaxszFTHOPr1I6w0",
    authDomain: "tomacado-391fb.firebaseapp.com",
    projectId: "tomacado-391fb",
    storageBucket: "tomacado-391fb.appspot.com",
    messagingSenderId: "1009656548397",
    appId: "1:1009656548397:web:2db061cf573f3a2370bca4",
  },
};

export default tomacadoConfig;
